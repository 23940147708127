@import "./themes/themes.css";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;

  width: 100vw;
}
html {
  max-width: 100%;
  overflow-x: hidden;
}
.splash-div {
  position: relative;
  display: flex;
  align-items: center;

  height: 10vh;

  width: 20vw;
}
.nav-img {
  position: relative;
  left: 21.5vw;
  height: 8vh;
  width: 6.2vw;
}
.address {
  color: #fff;
  font-size: 1.2vw;
  position: relative;
  left: 2vw;
  font-size: 1.3vw;
}

.splash-logo {
  padding-right: 1vh;
  height: 8vh;
  width: 8vh;
  position: relative;
  top: 0.2vh;
}
#invalid {
  position: relative;
  top: 10vh;
  color: red;
  display: none;
  left: 27vw;
}

input:focus,
textarea:focus,
button:focus select:focus {
  outline: none;
}

.main {
  animation: transition 0.6s forwards;
  background-color: var(--primary);
  height: 100vh;
  overflow-y: hidden;
}
.splash-screen {
  height: 100vh;
  width: 100vw;
  background-color: var(--primary);
}
.head {
  position: relative;
  margin-left: 22.5vw;
  top: 15vh;

  width: 60vw;
  height: 30vh;
}
.main-h1 {
  font-size: 5.5vw;
}
.main-question {
  position: relative;
  top: 30vh;
  font-size: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line1 {
  position: relative;
  top: 0.5vh;
  right: 3vw;
  height: 0.3vh;
  width: 15vw;
}
.line2 {
  position: relative;
  top: 0.5vh;
  left: 3vw;
  height: 0.3vh;
  width: 15vw;
}
.img-1 {
  position: relative;
  left: 3vw;
}
.img-2 {
  position: relative;
  right: 3vw;
}
.img-3 {
  position: relative;
  right: 8vw;
}
.img-4 {
  position: relative;
  right: 12.8vw;
}
.main-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 22.5vw;
  width: 55vw;
  position: relative;
  top: 40vh;
}
.main-buttons button {
  text-align: center;
  border: none;
  font-size: 1.5vw;
  border-radius: 10vw;
  height: 7vh;
  width: 20vh;
  color: var(--primary);
}
.help {
  color: white;
  font-size: 5.5vw;
  position: relative;
  top: 4vh;
}
.tell-us {
  color: var(--success);
  font-weight: normal;
  position: relative;
  font-size: 2.1vw;
  top: 6.5vh;
}
.main-buttons button:hover {
  background-color: var(--success);
  color: #fff;
  transition: 0.5s;
}
.main-buttons button:not(:hover) {
  transition: 0.5s;
  color: var(--primary);
}
.contacts {
  font-size: 2vw;
}
.nav {
  display: flex;
  align-items: center;
  overflow-x: visible;
  height: 10vh;
  width: 100vw;
}

.quit-queue {
  height: 100vh;
  width: 100vw;
  overflow-x: visible;
}
.splash-h1 {
  font-size: 4vw;
}

.queue-head {
  position: relative;
  top: 10vh;
}
.queue-images {
  position: relative;
  top: 18vh;
  display: flex;
  align-items: center;
  margin-left: 15vw;
  width: 70vw;
}
.queue-less {
  background-color: var(--primary);
  height: 100vh;
  width: 100vw;
}
.queue-animate {
  animation: transition 0.6s forwards;
}
.customer-animation {
  animation: bottomtotop 0.5s forwards;
}
.queue-less-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 19vh;
  height: 40vh;
}
.queue-less-head {
  position: relative;
  top: 24vh;
}
.power {
  height: 100vh;
  width: 100vw;
}

.power-head {
  position: relative;
  top: 10vh;
}
.data {
  background-color: var(--primary);
  height: 100vh;
}
.data-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 18vh;
  height: 40vh;
}
.data-head {
  position: relative;
  top: 15vh;
}
.safety {
  height: 100vh;
  width: 100vw;
}
.shop-btn {
  position: relative;
  right: 4vw;
  text-align: center;
  border: none;
  font-size: 1.5vw;
  border-radius: 10vw;
  height: 6.5vh;
  width: 22vh;
  background-color: var(--success);
  color: white;
}
.contact {
  background-color: var(--primary);
  height: 195vh;
  width: 100vw;
  animation: transition 0.6s forwards;
}
.contact-content {
  position: relative;
  left: 22.5vw;

  width: 70vw;
  height: 165vh;
}
.contact-form {
  position: relative;
  left: 0.5vw;
  top: 10vh;
  height: 70vh;
  width: 55vw;
}

.input1 {
  font-size: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border: none;
  position: relative;
  border-radius: 10vw;
  top: 3vh;
  width: 24vw;
  height: 6vh;
  background-color: #f0f0f0;
}
.input2 {
  font-size: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border: none;
  position: relative;
  border-radius: 10vw;
  top: 6.5vh;
  width: 50vw;
  height: 6vh;
  background-color: #f0f0f0;
}
.input3 {
  font-size: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border: none;
  position: relative;
  border-radius: 10vw;
  top: 10vh;
  width: 24vw;
  height: 6vh;
  background-color: #f0f0f0;
}
.input4 {
  font-size: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border: none;
  position: relative;
  border-radius: 10vw;
  top: 10vh;
  left: 2vw;
  width: 24vw;
  height: 6vh;
  background-color: #f0f0f0;
}
textarea {
  font-size: 1vw;
  position: relative;
  top: 14vh;
  border-radius: 2vw;
  width: 50vw;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 1vh;
  background-color: #f0f0f0;
}
.submit-btn {
  position: relative;
  text-align: center;
  border: none;
  font-size: 1.5vw;
  border-radius: 10vw;
  height: 6.5vh;
  width: 22vh;
  background-color: var(--success);
  color: white;
  top: 17vh;
}
.contact-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 22vh;
  height: 15vh;
  width: 50vw;
}
.email {
  height: 15vh;

  display: flex;
}
.number {
  display: flex;
  height: 15vh;
}
.contact-end {
  display: flex;
  align-items: center;
  position: relative;
  top: 36vh;

  height: 10vh;
  width: 50vw;
}
.footer {
  display: flex;
  align-items: center;
  position: relative;

  height: 13vh;
  background-color: #fff;
}
.footer-content {
  position: relative;
  left: 20vw;
}
.footer-h3 {
  font-size: 1.2vw;
}
.footer-p {
  font-size: 0.8vw;
}
.footer-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  left: 40vw;

  height: 6vh;
  width: 15vw;
}

.footer-imgs {
  height: 5vh;
  width: 4vw;
}
.about {
  animation: transition 0.6s forwards;
  height: 150vh;
  width: 100vw;
}

.about-content {
  position: relative;
  left: 22.5vw;

  width: 70vw;
  height: 130vh;
}

.about-profiles {
  position: relative;
  top: 20vh;
  right: 1vw;
  width: 70vw;
  height: 70vh;
}

.profile1 {
  position: relative;
  display: flex;
  height: 25vh;
  width: 70vw;
}
.profile2 {
  position: relative;
  display: flex;
  top: 7.5vh;
  height: 25vh;
  width: 70vw;
}
.profile3 {
  position: relative;
  display: flex;
  top: 15vh;
  height: 25vh;
  width: 70vw;
}

.profile-imgs {
  position: relative;
  left: 1vw;
  border-radius: 50%;
  height: 21vh;

  width: 10vw;
}

.main-h1 {
  line-height: 12vh;
}
.nav-link1 {
  position: relative;
  left: 60vw;
  top: 1vh;
  font-size: 1.5vw;
}
.nav-link2 {
  position: relative;
  left: 63vw;
  top: 1vh;
  font-size: 1.5vw;
}
.queue-h1 {
  position: relative;
  left: 22.5vw;
  font-size: 5.5vw;
}
.queue-p {
  position: relative;
  left: 23vw;
  top: 1.5vh;
  font-size: 2vw;
}
.queue-img {
  height: 40vh;
  width: 20vw;
}
.power-h1 {
  position: relative;
  left: 22.5vw;
  font-size: 5.5vw;
}
.power-p {
  position: relative;
  left: 23vw;
  top: 1.8vh;
  font-size: 2vw;
}
.power-img {
  height: 55vh;
  width: 30vw;
  position: relative;
  left: 38vw;
  top: 12vh;
}
.safety-h1 {
  position: relative;
  left: 22.5vw;
  font-size: 5.5vw;
}
.safety-p {
  position: relative;
  left: 23vw;
  top: 1.8vh;
  font-size: 2vw;
}
.safety-img {
  position: relative;
  left: 38vw;
  top: 11vh;
  height: 55vh;
  width: 30vw;
}
.shop-img {
  position: relative;
  left: 49.5vw;
  top: 18vh;
  height: 49vh;
  width: 27.5vw;
}
.register-h1 {
  color: white;
  font-size: 5.5vw;
  position: relative;
  left: 22.5vw;
}
.register-p {
  color: var(--success);
  font-weight: normal;
  position: relative;
  font-size: 2.1vw;
  left: 23vw;
  top: 1.5vh;
}
.register-img {
  width: 25vw;
}
.or {
  color: white;
  position: relative;
  top: 15vh;
  left: 1vw;
  font-weight: normal;
  font-size: 2.5vw;
}
.or-hr {
  border-color: var(--success);
  background-color: var(--success);
  position: relative;
  top: 12vh;
  left: 8vw;
  width: 45vw;
  height: 0.4vh;
}
.connect-with {
  color: white;
  font-size: 5.5vw;
  position: relative;
  top: 16vh;
}
.drop-us {
  font-size: 2vw;
  color: var(--success);
  position: relative;
  top: 17vh;
  left: 0.5vw;
  font-weight: normal;
}
.profile-content h2 {
  position: relative;
  left: 4.7vw;
  top: 1vh;
  font-weight: normal;
  font-size: 2vw;
}
.profile-urls {
  position: relative;
  left: 42vw;
  bottom: 3vh;
}
.profile-content p {
  position: relative;
  left: 4.7vw;

  font-weight: normal;
  font-size: 1.2vw;
}
.envelope {
  width: 5vw;
  height: 6vh;
}

.about-us {
  color: var(--primary);
  font-size: 5.5vw;
  position: relative;
  top: 4vh;
}
.small-group {
  font-weight: normal;
  position: relative;
  font-size: 2.1vw;
  top: 6.5vh;
}
.biller-email {
  font-size: 2vw;
}

/* Responsive design */

@media (orientation: portrait) {
  .contact-end {
    top: 25vh;
  }
  #phone {
    position: relative;
    left: 0vh;
  }
  .contact-details {
    position: relative;
    top: 22vh;
    height: 15vh;
    width: 70vw;
  }
  .help {
    font-size: 8vw;
  }
  .tell-us {
    font-size: 3.5vw;
  }

  .about-us {
    color: var(--primary);
    font-size: 7vw;
    position: relative;
    top: 4vh;
  }
  .small-group {
    font-weight: normal;
    position: relative;
    font-size: 3vw;
    top: 6.5vh;
  }

  .head h1 {
    line-height: 8vh;
  }
  .nav-img {
    position: relative;
    left: 12vw;
    height: 9vh;
    width: 9vw;
  }
  .main-buttons button {
    width: 29vw;
    height: 6.5vh;
    font-size: 4vw;
  }
  #customer-btn {
    position: relative;
    right: 7vh;
  }
  #retailer-btn {
    position: relative;
    left: 7vh;
  }

  .nav-link1 {
    position: relative;
    left: 50vw;
    top: 1vh;
    font-size: 3vw;
  }
  .nav-link2 {
    position: relative;
    left: 60vw;
    top: 1vh;
    font-size: 3vw;
  }
  .queue-h1 {
    position: relative;
    left: 10vw;
    font-size: 9vw;
  }
  .queue-p {
    position: relative;
    left: 10vw;
    top: 1.5vh;
    font-size: 4vw;
  }
  .queue-images {
    position: relative;
    top: 18vh;
    display: flex;
    align-items: center;
    right: 16vw;
    width: 70vw;
  }
  .queue-img {
    height: 30vh;
    width: 30vw;
  }

  .img-2 {
    position: relative;
    right: 5vw;
  }
  .img-3 {
    position: relative;
    right: 10vw;
  }
  .img-4 {
    position: relative;
    right: 14.8vw;
  }
  .quit-queue {
    width: 100vw;
    height: 70vh;
  }
  .power-h1 {
    position: relative;
    left: 10vw;
    font-size: 9vw;
  }
  .power-p {
    position: relative;
    left: 10vw;
    top: 1.5vh;
    font-size: 4vw;
  }
  .power {
    width: 100vw;
    height: 70vh;
  }
  .power-img {
    position: relative;
    top: 6vh;
    left: 30vw;
    height: 50vh;
    width: 60vw;
  }

  .safety-h1 {
    position: relative;
    left: 10vw;
    font-size: 9vw;
  }
  .safety-p {
    position: relative;
    left: 10vw;
    top: 1.8vh;
    font-size: 4vw;
  }
  .safety {
    width: 100vw;
    height: 70vh;
  }
  .safety-img {
    position: relative;
    left: 30vw;
    top: 3vh;
    width: 60vw;
  }
  .shop-p span:last-of-type {
    display: block;
  }
  .shop-btn {
    right: 30vw;
    top: -20vh;
    text-align: center;
    border: none;
    font-size: 2.5vw;
    border-radius: 10vw;
    height: 6vh;
    width: 17vh;
    background-color: var(--success);
    color: white;
  }
  .shop-img {
    position: relative;
    left: 49.5vw;
    top: 3vh;

    width: 40vw;
  }
  .queue-less {
    width: 100vw;
    height: 65vh;
  }
  .queue-less-img {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 8vh;
    height: 30vh;
  }
  .queue-less-head {
    position: relative;
    top: 10vh;
  }
  .data-head {
    position: relative;
    top: 4vh;
  }

  .data-img {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 6vh;
    height: 40vh;
  }
  .register-h1 {
    color: white;
    font-size: 8vw;
    position: relative;
    left: 10vw;
  }
  .register-p {
    color: var(--success);
    font-weight: normal;
    position: relative;
    font-size: 3.5vw;
    left: 10vw;
    top: 1.5vh;
  }
  .register-img {
    width: 50vw;
  }
  .input1 {
    font-size: 2.7vw;
    padding-left: 1vw;
    padding-right: 1vw;
    border: none;
    position: relative;
    border-radius: 10vw;
    top: 3vh;
    width: 50vw;
    height: 5vh;
    background-color: #f0f0f0;
  }
  .input2 {
    font-size: 2.7vw;

    border: none;
    position: relative;
    border-radius: 10vw;
    top: 5.5vh;
    width: 65vw;
    height: 4.5vh;
    background-color: #f0f0f0;
  }
  .input3 {
    font-size: 2.7vw;

    border: none;
    position: relative;
    border-radius: 10vw;
    top: 8vh;
    width: 50vw;
    height: 4.5vh;
    background-color: #f0f0f0;
  }

  .input4 {
    font-size: 2.7vw;
    display: inline-flex;
    border: none;
    position: relative;
    border-radius: 10vw;
    top: 10vh;
    left: 2vw;
    width: 50vw;
    height: 4.5vh;
    background-color: #f0f0f0;
  }

  textarea {
    font-size: 2.7vw;
    position: relative;
    top: 13vh;
    border-radius: 2vw;
    width: 65vw;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1vh;
    background-color: #f0f0f0;
  }
  .submit-btn {
    position: relative;
    text-align: center;
    border: none;
    font-size: 3.5vw;
    border-radius: 10vw;
    height: 5vh;
    width: 17vh;
    background-color: var(--success);
    color: white;
    top: 17vh;
  }
  .or {
    position: relative;
    top: 10vh;
    left: 1vw;
    font-weight: normal;
    font-size: 3.5vw;
  }
  .or-hr {
    border-color: var(--success);
    background-color: var(--success);
    position: relative;
    top: 8.5vh;
    left: 8vw;
    width: 45vw;
    height: 0.2vh;
  }
  .footer-content {
    position: relative;
    left: 10vw;
  }
  .connect-with {
    color: white;
    font-size: 8vw;
    position: relative;
    top: 16vh;
  }
  .drop-us {
    font-size: 3.5vw;
    color: var(--success);
    position: relative;
    top: 17vh;
    left: 0.5vw;
    font-weight: normal;
  }
  .contact {
    background-color: var(--primary);
    height: 155vh;
    width: 100vw;
  }
  .profile-urls {
    position: relative;
    height: 4vh;
    width: 7vw;
    left: 55vw;
  }
  .footer {
    display: flex;
    align-items: center;
    position: relative;
    height: 13vh;
  }
  .email {
    height: 8vh;
  }
  .number {
    height: 8vh;
  }
  .biller-email {
    font-size: 3vw;
    top: 2vh;
    left: 1vw;
  }
  .contacts {
    position: relative;
    top: 1.5vh;
    font-size: 3vw;
  }
  .profile-imgs {
    position: relative;
    left: 1vw;
    border-radius: 70%;
    height: 8vh;

    width: 8vh;
  }
  .about-profiles h2 {
    position: relative;
  }
  .about-content {
    height: 85vh;
    left: 13vw;
  }
  .about {
    height: 120vh;
  }
  .profile2 {
    position: relative;
    display: flex;
    top: 2vh;
    height: 25vh;
    width: 70vw;
  }
  .main {
    background-color: var(--primary);
    height: 100vh;
    overflow-y: hidden;
  }
  .profile3 {
    position: relative;
    display: flex;
    top: 2vh;
    height: 25vh;
    width: 70vw;
  }
  .splash-logo {
    position: relative;
    height: 6vh;
    width: 6vh;
    position: relative;

    padding-right: 1vh;
  }
  .splash-h1 {
    font-size: 7vw;
  }
  .splash-div {
    position: relative;

    width: 20vw;
  }
  .address {
    font-size: 2.5vw;
  }
  .footer-h3 {
    font-size: 2vw;
  }
  .footer-p {
    font-size: 1.2vw;
  }

  .profile-content p {
    position: relative;
    left: 4.7vw;

    font-weight: normal;
    font-size: 2.5vw;
  }
  .profile-content h2 {
    position: relative;
    left: 4.7vw;
    top: 1vh;
    font-weight: normal;
    font-size: 3.8vw;
  }
  .footer-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    left: 25vw;

    height: 7vh;
    width: 25vw;
  }
  .contact-content {
    width: 88vw;

    position: relative;
    left: 12vw;
  }
  .head {
    position: relative;
    margin-left: 5vw;
    top: 15vh;

    width: 90vw;
  }
  .main-h1 {
    flex-wrap: wrap;
    font-size: 6vh;
  }
  .main-h1 span:last-of-type {
    position: relative;
    text-align: center;
  }
  .line1 {
    position: relative;
    top: 0.5vh;
    right: 3vw;
    height: 0.3vh;
    width: 27vw;
  }
  .line2 {
    position: relative;
    top: 0.5vh;
    left: 3vw;
    height: 0.3vh;
    width: 27vw;
  }
  .main-question {
    position: relative;
    top: 30vh;
    font-size: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #invalid {
    font-size: 3vw;
    position: relative;
    top: 10vh;
    color: red;
    display: none;
    left: 1vw;
  }
}

@media only screen and (max-width: 600px) {
  .footer-imgs {
    width: 5.5vw;
  }
  #invalid {
    font-size: 3vw;
    position: relative;
    top: 10vh;
    color: red;
    display: none;
    left: 1vw;
  }
  .img-1 {
    width: 37vw;
  }
  .img-2 {
    width: 37vw;
  }
  .img-3 {
    width: 37vw;
  }
  .img-4 {
    display: none;
  }
  .nav-link1 {
    position: relative;
    left: 50vw;
    top: 1vh;
    font-size: 3.5vw;
  }
  .nav-link2 {
    position: relative;
    left: 60vw;
    top: 1vh;
    font-size: 3.5vw;
  }

  .shop-p span:last-of-type {
    display: block;
  }
  .quit-queue {
    height: 60vh;
  }
  .queue-images {
    position: relative;
    top: 10vh;
    display: flex;
    align-items: center;
    right: 15vw;
    width: 70vw;
  }

  .power {
    height: 60vh;
  }
  .safety {
    height: 60vh;
  }
  .safety-img {
    position: relative;
    left: 30vw;
    top: 3vh;
    width: 55vw;
  }
  .register-h1 {
    color: white;
    font-size: 8vw;
    position: relative;
  }
  .register-p {
    color: var(--success);
    font-weight: normal;
    position: relative;
    font-size: 3.5vw;
    bottom: 10vh;
  }
  .contact-end {
    top: 29vh;
  }
}

@media screen and (min-width: 750px) and (max-width: 1500px) and (orientation: portrait) {
  .splash-logo {
    position: relative;
    height: 6vh;
    width: 6vh;

    padding-right: 1vh;
  }
  .profile2 {
    position: relative;
    display: flex;
    top: 0vh;
    height: 25vh;
    width: 70vw;
  }

  .profile3 {
    position: relative;
    display: flex;
    top: -2vh;
    height: 25vh;
    width: 70vw;
  }
}

@media screen and (min-width: 750px) and (max-width: 1500px) and (orientation: landscape) {
  .splash-logo {
    position: relative;

    padding-right: 1vh;
  }
}

@keyframes transition {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes bottomtotop {
  from {
    position: relative;
    top: 100vh;
  }

  to {
    position: relative;
    top: 0vh;
  }
}
